<!-- *************************** 绑定地址 ******************************8 -->
<template>
  <div class="address_bind" v-loading="loading">
    <div class="no_email" v-if="!$store.state.userInfo.format_address||isModify">
      <p class="title">{{$store.state.userInfo.format_address?'修改地址':'绑定地址'}}</p>
      <div class="bind_email">
        <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
          <el-form-item prop="areaCode">
            <VueCountryIntl v-model="ruleForm.areaCode"></VueCountryIntl>
          </el-form-item>
          <el-form-item
            label="姓名"
            prop="addressee"
            :rules="[
              { required: true, message: '请输入姓名', trigger: 'blur' },
            ]"
          >
            <el-input
              type="text"
              placeholder="请输入姓名"
              v-model="ruleForm.addressee"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="手机号"
            prop="phone"
            :rules="[
              { required: true, message: '请输入手机号', trigger: 'blur' },
            ]"
          >
            <el-input type="text" placeholder="请输入手机号" v-model="ruleForm.phone" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
              label="地址"
              prop="address"
              :rules="[
              { required: true, message: '请输入地址', trigger: 'blur' },
            ]"
          >
            <el-input
                type="text"
                placeholder="请输入地址"
                v-model="ruleForm.address"
                autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="区"
            prop="address_detail"
            :rules="[
              { required: true, message: '请输入详细地址', trigger: 'blur' },
            ]"
          >
            <el-input
              type="text"
              placeholder="请输入地址"
              v-model="ruleForm.address_detail"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
              label="邮编"
              prop="postal_code"
              :rules="[
              { required: true, message: '请输入邮编', trigger: 'blur' },
            ]"
          >
            <el-input
                type="text"
                placeholder="请输入邮编"
                v-model="ruleForm.postal_code"
                autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
            >{{$store.state.userInfo.format_address?'修改地址':'绑定地址'}}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- *************************  有地址  *********************--->
    <div class="had_email" v-if="$store.state.userInfo.format_address&&isModify==false">
      <p class="bind_address">地址绑定</p>
      <div class="change_address">
        <p>
          <span class="detail">姓名：{{this.ruleForm.addressee}}</span>
          <span class="detail">电话：{{'+'+this.ruleForm.areaCode+' '+this.ruleForm.phone}}</span>
          <span class="detail">地址：{{this.ruleForm.address}}</span>
          <span class="detail">区：{{this.ruleForm.address_detail}}</span>
          <span class="detail">邮编：{{this.ruleForm.postal_code}}</span>
        </p>
        <span class="modify_text" @click="modifyEmail">修改</span>
      </div>
    </div>
  </div>
</template>
<script>
import {
  saveAddressApi,
  // getAddressListApi,
  getAddressInfoApi
} from "api/AdminCenter";
export default {
  data() {
    return {
      ruleForm: {
        areaCode: "61",//国籍
        addressee: "",//收件人
        phone: "",//电话
        // google_place_id: "",//地址id
        address: "",//地址
        address_detail: "",//详细地址
        postal_code: ""//邮编
      },
      addressList: [],
      isShow: true,//控制显示
      seconds: "",
      timer: null,
      isModify: false, //是否修改
      loading: true,
      getLongitude: "", //纬度
      getLatitude: "" //经度
    };
  },
  methods: {
    modifyEmail() {
      this.isModify = true;
    },
    //地址搜索
    // querySearch(queryString, cb) {
    //   let address = [];
    //   const params = {
    //     lat: this.getLatitude,
    //     lng: this.getLongitude,
    //     input: queryString
    //   };
    //   getAddressListApi(params).then(res => {
    //     if (res.code == 0) {
    //       address = res.data;
    //       if (address.length > 0) {
    //         address = address.map(item => {
    //           item.value = item.main_text;
    //           return item;
    //         });
    //         cb(address);
    //       }
    //     }
    //   });
    // },
    //地址选中
    // handleSelect(item) {
    //   const { place_id, main_text } = item;
    //   this.ruleForm.google_place_id = place_id;
    //   this.ruleForm.address = main_text;
    // },
    //提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let params = {};
          const {
            addressee,
            phone,
            // google_place_id,
            address,
            address_detail,
            postal_code,
            areaCode
          } = this.ruleForm;
          params = {
            addressee,
            phone: "+" + areaCode + " " + phone,
            // google_place_id,
            address,
            address_detail,
            postal_code
          };
          saveAddressApi(params).then(res => {
            if (res.code == 0) {
              this.$store.dispatch({
                type: "getUserInfo"
              });
              this.isModify = false;
            }
          });
        }
      });
    }
  },
  mounted() {
    if (this.$store.state.userInfo.format_address) {
      this.addressList = this.$store.state.userInfo.format_address.split(",");
    }
    // var _this = this;
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     //locationSuccess 获取成功的话
    //     function(position) {
    //       _this.getLongitude = position.coords.longitude; //经度
    //       _this.getLatitude = position.coords.latitude; //纬度
    //     },
    //     //locationError  获取失败的话
    //     function(error) {
    //       var errorType = [
    //         "您拒绝共享位置信息",
    //         "获取不到位置信息",
    //         "获取位置信息超时"
    //       ];
    //       alert(errorType[error.code - 1]);
    //     }
    //   );
    // }
    // console.log(this.getLongitude);
    //获取地址信息
    getAddressInfoApi()
      .then(res => {
        if (res.code == 0) {
          let {
            address,
            address_detail,
            postal_code,
            addressee,
            phone,
            // google_place_id
          } = res.data;
          const areaCode = phone.split(" ")[0].slice(1);
          phone = phone.split(" ")[1];
          this.ruleForm = {
            ...this.ruleForm,
            address,
            address_detail,
            postal_code,
            addressee,
            phone,
            // google_place_id,
            areaCode
          };
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
};
</script>
<style lang="scss">
.address_bind {
  padding: 50px;
  .no_email {
    margin-top: 100px;
    .title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .bind_email {
      width: 400px;
      margin: 0 auto;
      .vue-country-intl {
        .country-intl-label {
          width: auto !important;
          padding: 0 0 0 10px !important;
          box-sizing: border-box !important;
        }
        .country-intl-input-wrap {
          width: 100% !important;
          box-sizing: border-box !important;
        }
        .country-intl-input {
          width: 100% !important;
          box-sizing: border-box !important;
        }
        .vue-country-list-wrap {
          box-sizing: border-box;
        }
        .vue-country-list {
          width: 100% !important;
          li {
            padding: 0 10px !important;
            box-sizing: border-box;
            text-align: left;
          }
        }
      }
      .autocomplete-input {
        display: block;
      }
    }
  }
  .had_email {
    .modify_text {
      color: #517cfc;
      cursor: pointer;
    }
    .bind_address {
      text-align: left;
      font-size: 18px;
      font-weight: 600;
    }
    .change_address {
      .detail {
        display: block;
        margin-bottom: 20px;
        text-align: left;
      }
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      border-bottom: 1px solid #dcdfe6;
    }
  }
}
</style>
